<template>
    <b-table
        show-empty
        responsive
        small
        class="mb-0"
        head-variant="light"
        :items="employments || []"
        :fields="[
            {
                key: 'employerName',
                label: 'EMPLOYEER NAME',
            },
            {
                key: 'jobDescription',
                label: 'JOB DESCRIPTION',
            },
            {
                key: 'fromDate',
                label: 'START DATE',
            },
            {
                key: 'toDate',
                label: 'END DATE',
            },
            {
                key: 'monthlySalary',
                label: 'MONTHLY SALARY',
            },
        ]"
        hover
    >
        <template #cell(fromDate)="data">
            {{ $formatDate(data.item.fromDate) }}
        </template>
        <template #cell(toDate)="data">
            {{ $formatDate(data.item.toDate) }}
        </template>
        <template #cell(monthlySalary)="data">
            {{ $formatCurrency(data.item.monthlySalary) }}
        </template>
    </b-table>
</template>

<script>
export default {
    props: {
        employments: {
            type: Array,
        },
    },
};
</script>
