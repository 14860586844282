<template>
    <b-card no-body class="d-flex flex-column p-3" style="row-gap: 16px">
        <h3>{{ title }}</h3>
        <div class="border-bottom pb-4 row">
            <div class="col-4">
                <label class="mb-1">BlackList</label>
                <div>
                    <b-badge variant="danger" v-if="customer.blacklisted">Blacklisted</b-badge>
                    <p v-else>Not Blacklisted</p>
                </div>
                <p>{{ customer.blacklistedComments }}</p>
            </div>
            <div class="col-4">
                <label class="mb-1">Politically Exposed Person</label>
                <div>
                    <b-badge variant="danger" v-if="customer.pep">PEP</b-badge>
                    <p v-else>Not PEP</p>
                </div>
                <p>{{ customer.pepComments }}</p>
            </div>

            <div class="col-4">
                <label class="mb-1">Risk Rating</label>
                <div>
                    <b-badge
                        :style="{ background: customer.riskTypeColor }"
                        class="text-capitalize text-dark"
                        style="text-wrap: nowrap; font-weight: 700"
                        >{{ customer.riskTypeDescription }}</b-badge
                    >
                </div>
            </div>
        </div>

        <b-row class="py-4">
            <b-col cols="12">
                <b-table
                    small
                    show-empty
                    striped
                    borderless
                    emp
                    :items="scans"
                    :fields="[
                        { key: 'scanId', label: 'ID' },
                        { key: 'dateReview', label: 'Review Date' },
                        { key: 'riskTypeDescription', label: 'Risk Description' },
                        { key: 'matchedNumber', label: 'Matched Number' },
                        { key: 'reviewCompleted', label: 'Status' },
                        { key: 'riskTypeDecisionComments', label: 'Comments' },
                        { key: 'supervisorReviewComments', label: 'Supervisor Comments' },
                    ]"
                >
                    <template v-slot:cell(dateReview)="data">
                        {{ $formatDate(data.value) }}
                    </template>
                    <template v-slot:cell(reviewCompleted)="data">
                        <b-badge :variant="data.value ? 'success' : 'danger'" class="text-capitalize">{{
                            data.value ? 'completed' : 'pending'
                        }}</b-badge>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ComplianceInformation',
    props: {
        type: String,
        title: String,
        id: Number,
    },
    data() {
        return {
            customer: {},
            scans: [],
        };
    },
    computed: {
        ...mapGetters({
            mainCustomer: 'customer/getCustomer',
        }),
    },
    methods: {
        async getCustomerInfo() {
            if (this.type === 'applicant') {
                this.customer = this.mainCustomer;
            } else {
                const response = await this.$store.dispatch('customer/get_reference', this.id);
                this.customer = response || {};
            }

            this.scans = await this.$store.dispatch('customer/scans/get_scanById', this.id);
            console.log(this.customer);
        },
    },

    created() {
        this.getCustomerInfo();
    },
};
</script>
