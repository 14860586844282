<template>
    <div>
        <!-- ID Info Section -->
        <b-card-body class="p-2 border-bottom">
            <div class="d-flex justify-content-between pb-2">
                <h4 class="">CUSTOMER INFO</h4>
                <b-button size="sm" @click="goToApplicantDetail" variant="primary">View Details </b-button>
            </div>

            <div class="row" style="row-gap: 12px">
                <div class="col-3">
                    <label class="fw-medium mb-0">PROFILE CREATED</label>
                    <ItemText :text="applicant.createDate" isDate />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0" :class="{ 'text-danger': !applicant.lastName }">LASTNAME</label>
                    <ItemText :text="applicant.lastName" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0">MIDDLENAME</label>
                    <ItemText :text="applicant.middleName" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0" :class="{ 'text-danger': !applicant.firstName }">FIRSTNAME</label>
                    <ItemText :text="applicant.firstName" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0" :class="{ 'text-danger': !applicant.titleDescription }">TITLE</label>
                    <ItemText :text="applicant.titleDescription" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0" :class="{ 'text-danger': !applicant.address1 }">ADDRESS</label>
                    <ItemText :text="applicant.address1" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0" for="fname4">DISTRICT</label>
                    <ItemText :text="applicant.cityName" />
                </div>
            </div>
        </b-card-body>
        <!-- End Section -->

        <b-card-body class="p-2 border-bottom">
            <h4 class="">CONTACT INFO</h4>
            <div class="row">
                <div class="col-3">
                    <label class="fw-medium mb-0" :class="{ 'text-danger': !applicant.phone1 }">PHONE 1</label>
                    <ItemText :text="applicant.phone1" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0">PHONE 2</label>
                    <ItemText :text="applicant.phone2" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0" :class="{ 'text-danger': !applicant.email1 }">EMAIL 1</label>
                    <ItemText :text="applicant.email1" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-0">EMAIL 2</label>
                    <ItemText :text="applicant.email2" />
                </div>
            </div>
        </b-card-body>

        <!-- ID Info Section -->
        <b-card-body class="p-2 d-flex flex-column border-bottom" style="row-gap: 12px">
            <h4 class="mb-0">ID INFO</h4>
            <p
                class="text-danger"
                v-if="
                    !applicant.additionalDetails.cedula ||
                    !applicant.additionalDetails.passport ||
                    !applicant.additionalDetails.driverLicense
                "
            >
                Please verify that at least one of the three documents passport, Cedula or driver's license, is
                completed.
            </p>
            <div class="row">
                <div class="col-3">
                    <label
                        class="fw-medium mb-1"
                        :class="{
                            'text-danger': !applicant.additionalDetails.nationalID,
                        }"
                        >National ID</label
                    >
                    <ItemText :text="applicant.additionalDetails ? applicant.additionalDetails.nationalID : 'N/D'" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-1">Passport</label>
                    <ItemText :text="applicant.additionalDetails ? applicant.additionalDetails.passport : 'N/D'" />
                    <label class="fw-medium mb-1">Passport Exp. Date</label>
                    <ItemText
                        :text="applicant.additionalDetails ? applicant.additionalDetails.passportExpirationDate : 'N/D'"
                        isDate
                    />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-1">Driver's License</label>
                    <ItemText :text="applicant.additionalDetails ? applicant.additionalDetails.driverLicense : 'N/D'" />
                    <label class="fw-medium mb-1">DL Exp. Date</label>
                    <ItemText
                        :text="
                            applicant.additionalDetails
                                ? applicant.additionalDetails.driverLicenseExpirationDate
                                : 'N/D'
                        "
                        isDate
                    />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-1">Cedula</label>
                    <ItemText :text="applicant.additionalDetails ? applicant.additionalDetails.cedula : 'N/D'" />
                    <label class="fw-medium mb-1">Cedula Exp. Date</label>
                    <ItemText
                        :text="applicant.additionalDetails ? applicant.additionalDetails.cedulaExpirationDate : 'N/D'"
                        isDate
                    />
                </div>
            </div>
        </b-card-body>
        <!-- End Section -->

        <!-- Personalia Section -->

        <b-card-body class="p-2 d-flex flex-column border-bottom" style="row-gap: 12px">
            <h4 class="mb-0">Personal Info</h4>
            <div class="row">
                <div class="col-3">
                    <label
                        class="fw-medium mb-1"
                        :class="{ 'text-danger': !applicant.additionalDetails.placeOfBirthId }"
                        >Place of Birth</label
                    >
                    <ItemText
                        :text="applicant.additionalDetails ? applicant.additionalDetails.placeOfBirthName : 'N/D'"
                    />
                </div>
                <div class="col-3">
                    <label
                        class="fw-medium mb-1"
                        :class="{
                            'text-danger': !applicant.additionalDetails.nationality,
                        }"
                        >Nationality</label
                    >
                    <ItemText :text="applicant.additionalDetails ? applicant.additionalDetails.nationality : 'N/D'" />
                </div>
                <div class="col-3">
                    <label
                        class="fw-medium mb-1"
                        :class="{
                            'text-danger': !applicant.additionalDetails.birthDate,
                        }"
                        >Date of birth</label
                    >
                    <ItemText :text="applicant.additionalDetails ? applicant.additionalDetails.birthDate : 'N/D'" />
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <label
                        class="fw-medium mb-1"
                        :class="{
                            'text-danger': !genderName,
                        }"
                        >Gender</label
                    >
                    <ItemText :text="genderName" />
                </div>
                <div class="col-3">
                    <label
                        class="fw-medium mb-1"
                        :class="{
                            'text-danger': !applicant.additionalDetails.maritalStatusName,
                        }"
                        >Marital Status</label
                    >
                    <ItemText
                        :text="applicant.additionalDetails ? applicant.additionalDetails.maritalStatusName : 'N/D'"
                    />
                </div>
                <div
                    class="col-3"
                    v-if="applicant.additionalDetails && applicant.additionalDetails.maritalStatusId == 1"
                >
                    <label class="fw-medium mb-1">Spouses Full Name</label>
                    <ItemText :text="applicant.additionalDetails.spousesFullName" />
                </div>
                <div class="col-3">
                    <label class="fw-medium mb-1" for="comp4">Number of children</label>
                    <p class="mb-0">
                        {{ applicant.additionalDetails ? applicant.additionalDetails.numberDependentPersons : 'N/D' }}
                    </p>
                    <!-- <ItemText :text="applicant.additionalDetails.numberDependentPersons" /> -->
                </div>
            </div>
        </b-card-body>
        <!-- End Section -->

        <!-- <b-card-body v-if="loan.loanTypeId == 2" class="p-2 border-bottom">
                    <h4>Vehicle Info</h4>
                    <div class="row" style="row-gap: 4px">
                        <div class="col-4">
                            <label class="fw-medium mb-1">Brand</label>
                            <ItemText>{{ dataSelected.brand }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Model</label>
                            <ItemText>{{ dataSelected.model }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Year</label>
                            <ItemText>{{ dataSelected.year }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">VIN</label>
                            <ItemText>{{ dataSelected.vin }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Condition</label>
                            <ItemText>{{ dataSelected.condition }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Color</label>
                            <ItemText>{{ dataSelected.color }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Mileage</label>
                            <ItemText>{{ dataSelected.mileage }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Price</label>
                            <ItemText>{{ dataSelected.price }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Appraisal</label>
                            <ItemText>{{ dataSelected.appraisal }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Seller Name</label>
                            <ItemText>{{ dataSelected.sellerName }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Seller Contact</label>
                            <ItemText>{{ dataSelected.sellerContact }}</ItemText>
                        </div>

                        <div class="col-4">
                            <label class="fw-medium mb-1">Seller Address</label>
                            <ItemText>{{ dataSelected.sellerAddress }}</ItemText>
                        </div>
                    </div>
                </b-card-body> -->

        <b-card-body class="p-2 border-bottom">
            <h4 class="">EMPLOYMENT INFO</h4>
            <DetailsEmploymentsTable :employments="applicant.employments" />
        </b-card-body>

        <!-- Bank Info -->
        <b-card-body
            v-if="applicant.additionalDetails && applicant.additionalDetails.bankId != -1"
            class="p-2 border-bottom"
        >
            <h4>Bank Accounts</h4>
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-4">
                            <label class="fw-medium mb-1" for="comp4">#</label>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1" for="comp4">Bank</label>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1" for="comp4">Account Number</label>
                        </div>
                    </div>
                    <div class="row" v-if="applicant.additionalDetails.bankId != -1">
                        <div class="col-4">
                            <ItemText :text="'1'" />
                        </div>
                        <div class="col-4">
                            <ItemText :text="applicant.additionalDetails.bankName" />
                        </div>
                        <div class="col-4">
                            <ItemText :text="applicant.additionalDetails.bankAccountNumber" />
                        </div>
                    </div>
                    <div class="row" v-if="applicant.additionalDetails.bankId2 != -1">
                        <div class="col-4">
                            <ItemText :text="2" />
                        </div>
                        <div class="col-4">
                            <ItemText :text="applicant.additionalDetails.bankName2" />
                        </div>
                        <div class="col-4">
                            <ItemText :text="applicant.additionalDetails.bankAccountNumber2" />
                        </div>
                    </div>
                </div>
                <template v-if="applicant.additionalDetails.bankId != -1">
                    <div class="col-6"></div>
                </template>
            </div>
        </b-card-body>
        <!-- End Section -->

        <b-card-body class="p-2 border-bottom">
            <h4>REFERENCES</h4>
            <div class="row">
                <div class="col-4" v-for="(reference, idx) in applicant.references" :key="reference.id">
                    <h5>REFERENCE {{ idx + 1 }}</h5>
                    <div class="">
                        <label class="fw-medium mb-1" for="fname4">Name</label>
                        <ItemText :text="reference.name" />
                    </div>
                    <div class="">
                        <label class="fw-medium mb-1" for="fname4">Contact Number</label>
                        <ItemText :text="reference.contactNumber" />
                    </div>

                    <div class="">
                        <label class="fw-medium mb-1" for="fname4">Address</label>
                        <ItemText :text="reference.address" />
                    </div>

                    <div class="">
                        <label class="fw-medium mb-1" for="fname4">Relation to client</label>
                        <ItemText :text="reference.relationTypeDescription" />
                    </div>
                </div>
            </div>
        </b-card-body>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ItemText from '../../ItemText.vue';
import DetailsEmploymentsTable from './DetailsEmploymentsTable.vue';
export default {
    components: {
        ItemText,
        DetailsEmploymentsTable,
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            authorizations: 'auth/getAuthorizations',
            countries: 'general/countries/getCountries',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Additional-Details');
        },
        placeOfBirthName() {
            if (!this.applicant?.additionalDetails?.placeOfBirthId) return '';
            return this.countries.find((country) => country.id == this.applicant.additionalDetails.placeOfBirthId)
                ?.name;
        },
        genderName() {
            if (this.applicant?.additionalDetails?.gender == 'F') return 'Female';
            if (this.applicant?.additionalDetails?.gender == 'M') return 'Male';
            return null;
        },
    },

    methods: {
        goToApplicantDetail() {
            this.$router.push(`/customers/list/${this.applicant.id}`);
        },
    },
};
</script>
