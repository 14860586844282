var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"d-flex flex-column p-3",staticStyle:{"row-gap":"16px"},attrs:{"no-body":""}},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"border-bottom pb-4 row"},[_c('div',{staticClass:"col-4"},[_c('label',{staticClass:"mb-1"},[_vm._v("BlackList")]),_c('div',[(_vm.customer.blacklisted)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Blacklisted")]):_c('p',[_vm._v("Not Blacklisted")])],1),_c('p',[_vm._v(_vm._s(_vm.customer.blacklistedComments))])]),_c('div',{staticClass:"col-4"},[_c('label',{staticClass:"mb-1"},[_vm._v("Politically Exposed Person")]),_c('div',[(_vm.customer.pep)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("PEP")]):_c('p',[_vm._v("Not PEP")])],1),_c('p',[_vm._v(_vm._s(_vm.customer.pepComments))])]),_c('div',{staticClass:"col-4"},[_c('label',{staticClass:"mb-1"},[_vm._v("Risk Rating")]),_c('div',[_c('b-badge',{staticClass:"text-capitalize text-dark",staticStyle:{"text-wrap":"nowrap","font-weight":"700"},style:({ background: _vm.customer.riskTypeColor })},[_vm._v(_vm._s(_vm.customer.riskTypeDescription))])],1)])]),_c('b-row',{staticClass:"py-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"small":"","show-empty":"","striped":"","borderless":"","emp":"","items":_vm.scans,"fields":[
                    { key: 'scanId', label: 'ID' },
                    { key: 'dateReview', label: 'Review Date' },
                    { key: 'riskTypeDescription', label: 'Risk Description' },
                    { key: 'matchedNumber', label: 'Matched Number' },
                    { key: 'reviewCompleted', label: 'Status' },
                    { key: 'riskTypeDecisionComments', label: 'Comments' },
                    { key: 'supervisorReviewComments', label: 'Supervisor Comments' } ]},scopedSlots:_vm._u([{key:"cell(dateReview)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$formatDate(data.value))+" ")]}},{key:"cell(reviewCompleted)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"variant":data.value ? 'success' : 'danger'}},[_vm._v(_vm._s(data.value ? 'completed' : 'pending'))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }