<template>
    <b-overlay :show="loadingData">
        <!-- 1 -->
        <b-card no-body class="mb-4">
            <template>
                <Header />
                <hr class="mb-2" />
            </template>
            <!-- Search Control -->
            <div class="mb-2 d-flex justify-content-between">
                <b-form-group class="mb-2 w-25">
                    <b-input-group>
                        <b-form-input
                            size="sm"
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button variant="danger" size="sm" :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                            >
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <div class="mb-2 d-flex align-items-center">
                    <h5 class="mb-0 mr-2">
                        {{ this.loanApplication.loanProcessStatusLog.currentStatusCode }} -
                        {{ this.loanApplication.loanProcessStatusLog.currentStatusName }}
                    </h5>
                    <b-button variant="primary" @click="showStatusModal">Add new status</b-button>
                </div>
            </div>
            <!-- End Section -->

            <!-- Data Table -->
            <b-table
                style="min-height: 250px"
                show-empty
                responsive
                small
                class="mb-0"
                head-variant="light"
                :items="status"
                :filter="filter"
                :fields="fields"
                hover
            >
                <template v-slot:cell(date)="data">
                    {{ formatDate(data.item.date) }}
                </template>
            </b-table>
            <!-- End Section -->

            <!-- Pagination -->
            <div class="p-3 d-flex align-items-center">
                <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                </b-form-group>
                <div class="ml-auto">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="0"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                    ></b-pagination>
                </div>
            </div>
            <!-- End Section -->
        </b-card>

        <!-- Modal add status with select status types and textarea for comments -->

        <!-- Modal -->
        <b-modal id="modal-add-status" title="Add new status" hide-footer centered>
            <b-form @submit.prevent="addStatus">
                <div class="d-flex justify-content-between align-items-end">
                    <b-form-group label="Status Type" label-for="status-type-select" class="mb-0 w-100">
                        <b-form-select
                            id="status-type-select"
                            loading-text="Loading..."
                            v-model="statusCode"
                            :options="[{ code: null, description: 'Select status' }, ...options]"
                            :disabled="loadingOptions"
                            text-field="description"
                            value-field="code"
                            required
                        ></b-form-select>
                    </b-form-group>
                    <b-spinner width="20px" class="mb-2 ml-2" small v-if="loadingOptions"></b-spinner>
                </div>

                <b-form-group label="Comments" label-for="comments-textarea">
                    <b-form-textarea
                        id="comments-textarea"
                        v-model="comment"
                        placeholder="Add a comment"
                    ></b-form-textarea>
                </b-form-group>
                <div class="d-flex justify-content-end">
                    <b-button type="submit" variant="primary" :disabled="loading">
                        <b-spinner small v-if="loading"></b-spinner>
                        <span v-else>Submit</span>
                    </b-button>
                </div>
            </b-form>
        </b-modal>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import dayjs from 'dayjs';
export default {
    name: 'ApplicationStatus',
    components: {
        Header,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        fields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'statusCode',
                label: 'Code',
            },
            {
                key: 'statusName',
                label: 'Status',
            },
            {
                key: 'createdByUserName',
                label: 'User',
            },
            {
                key: 'comments',
                label: 'Comments',
            },
            // {
            //     key: 'actions',
            //     label: 'Actions',
            // },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],

        options: [],
        statusCode: null,
        comment: null,
        loading: false,
        loadingData: false,
        loadingOptions: false,
    }),
    methods: {
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
        },
        showStatusModal() {
            this.$bvModal.show('modal-add-status');
            this.getOptions();
        },
        async getData() {
            this.loadingData = true;
            await this.$store.dispatch(
                'loan/applications/get_loanApplicationById',
                this.$route.query.loanApplicationId,
            );

            this.loadingData = false;
        },
        async getOptions() {
            this.loadingOptions = true;
            const response = await this.$store.dispatch(
                'loan/applications/get_childStatusByParentCode',
                this.loanApplication?.loanProcessStatusLog?.currentStatusCode,
            );
            this.options = response ?? [];
            this.loadingOptions = false;
        },
        async addStatus() {
            this.loading = true;

            const payload = {
                comments: this.comment,
                statusCode: this.statusCode,
                loanApplicationId: this.$route.query.loanApplicationId,
                createdToUser: null,
            };
            await this.$store.dispatch('loan/applications/add_processStatus', payload);
            await this.$store.dispatch(
                'loan/applications/get_loanApplicationById',
                this.$route.query.loanApplicationId,
            );
            await this.getData();
            this.$bvModal.hide('modal-add-status');
            this.comment = null;
            this.statusCode = null;
            this.loading = false;
        },
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        status() {
            return this.loanApplication?.loanProcessStatusLog?.loanProcessStatusLog ?? [];
        },
    },
    mounted() {},
    created() {
        this.getOptions();
    },
};
</script>
