<template>
    <b-overlay :show="loading" rounded="sm">
        <b-row v-if="!loading">
            <b-col cols="12">
                <b-card no-body class="mb-4">
                    <b-card-body class="p-3">
                        <b-tabs content-class="mt-3" align="left" v-model="tabIndex">
                            <b-tab title="Main" active lazy>
                                <ApplicationMain />
                            </b-tab>

                            <b-tab title="Details" lazy>
                                <ApplicationDetails />
                            </b-tab>

                            <b-tab title="Vehicle" v-if="loanApplication.loanTypeId == 2" lazy>
                                <ApplicationVehicle />
                            </b-tab>

                            <b-tab title="Documents" lazy>
                                <ApplicationDocuments />
                            </b-tab>

                            <b-tab title="Comments" lazy>
                                <ApplicationComments />
                            </b-tab>

                            <b-tab title="Compliance" @click="hadleCompliance" lazy>
                                <ApplicationCompliance />
                            </b-tab>

                            <b-tab title="Screening" lazy>
                                <ApplicationScreening />
                            </b-tab>

                            <b-tab title="Job Verification" lazy>
                                <ApplicationJobVerification />
                            </b-tab>

                            <b-tab title="Finance" lazy>
                                <ApplicationFinance />
                            </b-tab>

                            <b-tab title="Summary" lazy>
                                <ApplicationSummary />
                            </b-tab>

                            <b-tab title="Loan Setup" lazy>
                                <ApplicationLoanSetup />
                            </b-tab>

                            <b-tab title="Status" lazy>
                                <ApplicationStatus />
                            </b-tab>

                            <b-tab title="Digital Sign" lazy>
                                <ApplicationDigitalSign />
                            </b-tab>
                        </b-tabs>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import ApplicationDetails from '@/components/applications/ApplicationDetails.vue';
import ApplicationDocuments from '@/components/applications/ApplicationDocuments.vue';
import ApplicationJobVerification from '@/components/applications/ApplicationJobVerification.vue';
import ApplicationCompliance from '@/components/applications/ApplicationCompliance.vue';
import ApplicationScreening from '../../components/applications/ApplicationScreening.vue';
import ApplicationSummary from '../../components/applications/ApplicationSummary.vue';
import ApplicationLoanSetup from '../../components/applications/ApplicationLoanSetup.vue';
import ApplicationFinance from '../../components/applications/ApplicationFinance.vue';
import ApplicationStatus from '../../components/applications/ApplicationStatus.vue';
import ApplicationComments from '../../components/applications/ApplicationComments.vue';
import ApplicationDigitalSign from '../../components/applications/ApplicationDigitalSign.vue';
import ApplicationMain from '@/components/applications/ApplicationMain.vue';
import ApplicationVehicle from '../../components/applications/ApplicationVehicle.vue';

import { mapGetters } from 'vuex';

export default {
    name: 'Customer',
    components: {
        ApplicationMain,
        ApplicationDetails,
        ApplicationDocuments,
        ApplicationComments,
        ApplicationJobVerification,
        ApplicationCompliance,
        ApplicationScreening,
        ApplicationSummary,
        ApplicationLoanSetup,
        ApplicationFinance,
        ApplicationStatus,
        ApplicationDigitalSign,
        ApplicationVehicle,
    },
    data: () => ({
        loading: true,
        tabIndex: 0,
    }),
    computed: {
        ...mapGetters({
            loanApplication: 'loan/applications/getLoanApplication',
        }),
    },
    methods: {
        async getData() {
            this.loading = true;
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            await this.$store.dispatch('general/countries/get_allCountries', { skip: 1, take: 9999 });
            await this.$store.dispatch('loan/applications/get_loanById', this.$route.query.loanApplicationId);
            await this.$store.dispatch(
                'loan/applications/get_loanApplicationById',
                this.$route.query.loanApplicationId,
            );

            await this.$store.dispatch(
                'customer/productRequest/get_processResquest',
                this.$route.query.loanApplicationId,
            );

            this.loading = false;
        },
        hadleCompliance() {},
    },
    created() {
        this.getData();
    },
};
</script>
