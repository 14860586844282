<template>
    <div>
        <b-card no-body>
            <b-form @submit.prevent="saveData">
                <label class="fw-medium mb-1" for="document-types">Customer Type</label>
                <b-form-select
                    id="document-types"
                    :options="[{ value: null, text: 'Please select an option', disabled: true }, ...optionsCustomer]"
                    v-model="customerType"
                    required
                ></b-form-select>
                <b-form-group label="Bank" label-for="bank">
                    <b-form-select v-model="bankSelected" id="bank" required>
                        <option :value="null" disabled>Select a bank</option>
                        <option v-for="bank in banks" :key="bank.id" :value="bank.id">{{ bank.name }}</option>
                    </b-form-select>
                </b-form-group>

                <!-- Campo: Tipo de obligación -->
                <b-form-group label="Type" label-for="obligationtype">
                    <b-form-select v-model="obligationtype" id="obligationtype" required>
                        <option :value="null" disabled>Select obligation type</option>
                        <option value="1">Loan</option>
                        <option value="2">Credit Card</option>
                        <option value="3">Mortgage</option>
                        <option value="4">Other</option>
                    </b-form-select>
                </b-form-group>

                <!-- Campo: Fecha -->
                <b-form-group label="Start Date" label-for="obligationdate">
                    <b-form-input
                        type="date"
                        v-model="obligationdate"
                        id="obligationdate"
                        placeholder="Enter date"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Amount" label-for="amount">
                    <b-form-input
                        v-model="amount"
                        id="amount"
                        type="number"
                        required
                        placeholder="Enter amount"
                    ></b-form-input>
                </b-form-group>

                <!-- Monthly Payment Field -->
                <b-form-group label="Monthly Payment" label-for="monthlypayment">
                    <b-form-input
                        v-model="monthlypayment"
                        id="monthlypayment"
                        type="number"
                        required
                        placeholder="Enter monthly payment"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Comment" label-for="comment">
                    <b-form-textarea v-model="comment" id="comment" placeholder="Enter comment"></b-form-textarea>
                </b-form-group>
                <div class="d-flex justify-content-end mb-3">
                    <b-button variant="primary" type="submit" :disabled="loading">
                        <b-spinner small v-if="loading"></b-spinner>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </b-form>
        </b-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Obligations',
    props: {
        readOnly: Boolean,
    },
    data() {
        return {
            // Fields and states
            bankSelected: null,
            obligationtype: null,
            obligationdate: '',
            amount: null,
            monthlypayment: null,
            comment: '',
            customerType: null,
            loading: false,
            obligations: [],
            banks: [],
            fields: [
                { key: 'bankid', label: 'Bank Id' },
                { key: 'bankname', label: 'Bank Name' },
                { key: 'obligationtype', label: 'Obligation Type' },
                { key: 'obligationdate', label: 'Date' },
                { key: 'amount', label: 'Amount' },
                { key: 'monthlypayment', label: 'Monthly Payment' },
                { key: 'action', label: 'Action' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            loanApplication: 'loan/applications/getLoanApplication',
            optionsCustomer: 'loan/applications/getCustomerTypes',
        }),
    },
    methods: {
        async getBanks() {
            let data = { skip: 1, take: 50 };
            const response = await this.$store.dispatch('general/banks/get_allBanks', data);
            this.banks = response?.dataResult ?? [];
        },
        resetForm() {
            this.bankSelected = null;
            this.obligationtype = '';
            this.obligationdate = '';
            this.amount = null;
            this.monthlypayment = null;
        },
        deleteObligation(index) {
            this.obligations.splice(index, 1);
            this.saveData();
        },
        async saveData() {
            this.loading = true;
            await this.$store.dispatch('customer/screening/add_screeningVerification', {
                bankId: this.bankSelected,
                loanApplicationId: this.$route.query.loanApplicationId,
                customerId: this.customerType,
                amount: this.amount,
                montlyPayment: this.monthlypayment,
                obligationType: this.obligationtype,
                startDate: this.obligationdate || '1950-01-01T00:00:00.005Z',
                comment: this.comment,
            });
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.query.loanApplicationId);
            this.$bvModal.hide('modal-add-obligation');
            this.loading = false;
        },
    },
    created() {
        this.getBanks();
    },
};
</script>
