<template>
    <b-card no-body>
        <template v-if="!readOnly">
            <Header />
            <hr class="mb-2" />
        </template>
        <div class="d-flex flex-column bg-light p-3" style="row-gap: 12px">
            <ComplianceInformation
                :id="loanApplication.applicantInfo.id"
                type="applicant"
                title="Customer Information"
            />
            <ComplianceInformation
                v-if="loanApplication.coApplicantInfo && loanApplication.coApplicantInfo.id != 0"
                :id="loanApplication.coApplicantInfo.id"
                type="coApplicant"
                title="Co-Applicant Information"
            />
            <ComplianceInformation
                v-if="loanApplication.guarantorInfo && loanApplication.guarantorInfo.id != 0"
                :id="loanApplication.guarantorInfo.id"
                type="guarantor"
                title="Guarantor Information"
            />
        </div>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import ComplianceInformation from './compliance/ComplianceInformation.vue';
export default {
    components: {
        Header,
        ComplianceInformation,
    },
    name: 'ApplicationCompliance',
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        firstName: '',
        lastName: '',
        loading: false,
        scans: [],
        selectedMatch: null,
        loadingPep: false,
        pep: false,
        pepConfirm: null,
        typeRisk: null,
        decision: '',
        idType: null,
        identification: '',
        expDate: '',
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Membercheck');
        },
    },
    methods: {},
};
</script>
