<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                    <Header />
                    <hr class="mb-4" />

                    <div>
                        <div class="d-flex justify-content-between align-items-center mb-3 mt-5">
                            <h3 class="">Sign Process</h3>

                            <b-button
                                v-if="hasPermissionSign"
                                :variant="'primary'"
                                :disabled="loadingSignProcess"
                                @click="createSignProcess"
                            >
                                <b-spinner small v-if="loadingSignProcess"></b-spinner>
                                Start Process
                            </b-button>
                        </div>

                        <b-form-group label="Type of signature selected" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                v-model="signSelected"
                                :options="[
                                    { text: 'Digital Sign', value: 'digital' },
                                    { text: 'Manual Sign', value: 'manual' },
                                ]"
                                :aria-describedby="ariaDescribedby"
                                name="sign"
                                @change="updateSignature"
                            ></b-form-radio-group>
                        </b-form-group>

                        <b-list-group v-if="signProcess.length > 0">
                            <b-list-group-item>
                                <div
                                    class="d-flex w-100 justify-content-between align-items-center border-bottom mb-2"
                                    v-for="item in signProcess"
                                    :key="item.code"
                                >
                                    <div>
                                        <h5>{{ item.documentStatusKey }}</h5>
                                        <p class="text-muted">{{ item.createDate.split('T')[0] }}</p>
                                    </div>
                                    <feather type="check-circle" class="feather-md text-success"></feather>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
export default {
    name: 'ApplicationDigitalSign',
    components: {
        Header,
    },

    data: () => ({
        loading: false,
        loadingSignProcess: false,
        status: null,
        serviceSelected: null,
        signSelected: null,
        signProcess: [],
        loadingAddComment: false,
        comment: '',
    }),
    methods: {
        async getData() {
            await this.$store.dispatch(
                'customer/productRequest/get_processResquest',
                this.$route.query.loanApplicationId,
            );
            await this.getSignProcess();
        },
        async getSignProcess() {
            const response = await this.$store.dispatch(
                'loan/signature/get_signProcess',
                this.$route.query.loanApplicationId,
            );
            this.signProcess = response;
        },
        async createSignProcess() {
            this.loadingSignProcess = true;
            await this.$store.dispatch(`loan/signature/create_signProcess`, this.$route.query.loanApplicationId);
            await this.getData();
            this.loadingSignProcess = false;
        },
        async updateSignature() {
            await this.$store.dispatch(`loan/applications/update_typeSignature`, {
                id: this.$route.query.loanApplicationId,
                isDigitalSignature: this.signSelected == 'digital',
            });
            await this.getData();
        },
    },
    computed: {
        ...mapGetters({
            process: 'customer/productRequest/getProcess',
            customer: 'customer/getCustomer',
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermissionSign() {
            return this.authorizations.includes('/LoanApplications-Signature');
        },
    },
    created() {
        this.getSignProcess();
    },
};
</script>
