<template>
    <div>
        <b-card no-body class="mb-4">
            <template v-if="!readOnly">
                <Header />
                <hr class="mb-2" />
            </template>

            <b-row>
                <b-col xl="6" cols="12">
                    <h3 class="mb-2">Incomes</h3>
                    <b-form @submit.prevent="addIncome" class="mb-2">
                        <div class="row">
                            <div class="col-3">
                                <b-form-select
                                    v-model="incomeType"
                                    id="incomeType"
                                    size="sm"
                                    required
                                    text-field="description"
                                    value-field="id"
                                    :options="[{ id: null, description: 'Please select an option' }, ...incomesTypes]"
                                />
                            </div>
                            <div class="col-3">
                                <b-form-select
                                    id="incomeCustomerType"
                                    v-model="incomeCustomerType"
                                    :options="[{ value: null, text: 'Please select an option' }, ...customerTypes]"
                                    size="sm"
                                />
                            </div>
                            <div class="col-3">
                                <b-form-input
                                    v-model="incomeAmount"
                                    id="amount"
                                    min="0"
                                    size="sm"
                                    type="number"
                                    step="0.01"
                                    required
                                    placeholder="Enter amount"
                                />
                            </div>
                            <div class="col-3 d-flex justify-content-end">
                                <b-button size="sm" variant="primary" type="submit" :disabled="loading">
                                    Add Income
                                </b-button>
                            </div>
                        </div>
                    </b-form>

                    <!-- Listado de ingresos -->
                    <b-table striped hover small :items="incomes" header class="" :fields="fieldsIncomes">
                        <template #cell(type)="data">
                            <b>{{ data.item.type }}</b>
                        </template>
                        <template #cell(amount)="data"> AWG {{ data.item.amount }} </template>

                        <template #cell(action)="data">
                            <b-dropdown size="sm" variant="outline-primary" text="Actions" right>
                                <b-dropdown-item @click="showEditIncomeModal(data.item)">Edit</b-dropdown-item>
                                <b-dropdown-item @click="deleteIncome(data.item.id)">Delete</b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                    <div class="row">
                        <div class="col-6"></div>
                        <div class="col-6 d-flex justify-content-between">
                            <h6 class="mb-0">Total Incomes</h6>
                            <p class="mb-1">AWG {{ totalIncomes }}</p>
                        </div>
                    </div>
                </b-col>

                <b-col xl="6" cols="12">
                    <h3 class="mb-2">Expenses</h3>

                    <b-form @submit.prevent="addExpense">
                        <div class="row">
                            <div class="col-3">
                                <b-form-select
                                    v-model="expenseType"
                                    id="expenseType"
                                    size="sm"
                                    required
                                    text-field="description"
                                    value-field="id"
                                    :options="[{ id: null, description: 'Please select an option' }, ...expensesTypes]"
                                />
                            </div>
                            <div class="col-3">
                                <b-form-select
                                    id="expenseCustomerType"
                                    v-model="expenseCustomerType"
                                    :options="[{ value: null, text: 'Please select an option' }, ...customerTypes]"
                                    required
                                    size="sm"
                                />
                            </div>
                            <div class="col-3">
                                <b-form-input
                                    v-model="expenseAmount"
                                    required
                                    min="0"
                                    id="expenseAmount"
                                    step="0.01"
                                    type="number"
                                    size="sm"
                                    placeholder="Enter amount"
                                />
                            </div>
                            <div class="col-3 d-flex justify-content-end mb-2">
                                <b-button size="sm" variant="danger" type="submit" :disabled="loading">
                                    Add Expense
                                </b-button>
                            </div>
                        </div>
                    </b-form>

                    <!-- Listado de gastos -->
                    <b-table striped hover small :items="expenses" :fields="fieldsExpenses">
                        <template #cell(type)="data">
                            <b>{{ data.item.type }}</b>
                        </template>
                        <template #cell(amount)="data"> AWG {{ data.item.amount }} </template>
                        <template #cell(action)="data">
                            <b-dropdown size="sm" variant="outline-primary" text="Actions" right>
                                <b-dropdown-item @click="showEditExpenseModal(data.item)">Edit</b-dropdown-item>
                                <b-dropdown-item @click="deleteExpenses(data.item.id)">Delete</b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    <div class="row">
                        <div class="col-6"></div>
                        <div class="col-6 d-flex justify-content-between">
                            <h6 class="mb-0">Total Expenses</h6>
                            <p class="mb-1">AWG {{ totalExpenses }}</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <!-- Modal Edit Income -->
        <b-modal id="editIncomeModal" title="Edit Income" size="sm" centered hide-footer>
            <b-form @submit.prevent="editIncome">
                <b-form-group label="Income Type" label-for="incomeType">
                    <b-form-select
                        v-model="incomeType"
                        id="incomeType"
                        size="sm"
                        required
                        text-field="description"
                        value-field="id"
                        :options="incomesTypes"
                    />
                </b-form-group>

                <b-form-group label="Amount" label-for="incomeAmount">
                    <b-form-input
                        v-model="incomeAmount"
                        id="incomeAmount"
                        min="0"
                        size="sm"
                        type="number"
                        required
                        placeholder="Enter amount"
                    />
                </b-form-group>

                <div class="d-flex justify-content-end">
                    <b-button type="submit" variant="primary" :disabled="loading">Save</b-button>
                </div>
            </b-form>
        </b-modal>

        <!-- Modal Edit Expense -->
        <b-modal id="editExpenseModal" title="Edit Expense" size="sm" centered hide-footer>
            <b-form @submit.prevent="editExpenses">
                <b-form-group label="Expense Type" label-for="expenseType">
                    <b-form-select
                        v-model="expenseType"
                        id="expenseType"
                        size="sm"
                        required
                        text-field="description"
                        value-field="id"
                        :options="expensesTypes"
                    />
                </b-form-group>

                <b-form-group label="Amount" label-for="expenseAmount">
                    <b-form-input
                        v-model="expenseAmount"
                        id="expenseAmount"
                        min="0"
                        size="sm"
                        type="number"
                        required
                        placeholder="Enter amount"
                    />
                </b-form-group>

                <div class="d-flex justify-content-end">
                    <b-button type="submit" variant="primary" :disabled="loading">Save</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ApplicationFinance',
    components: {
        Header,
    },
    props: {
        readOnly: Boolean,
    },
    data() {
        return {
            // Incomes
            incomeType: null,
            incomeAmount: null,
            incomeCustomerType: null,

            // Expenses
            expenseType: null,
            expenseAmount: null,
            expenseCustomerType: null,

            incomesTypes: [],
            expensesTypes: [],
            incomeSelected: null,
            expenseSelected: null,

            fieldsIncomes: [
                { key: 'id', label: 'ID' },
                { key: 'incomeDescription', label: 'Description' },
                { key: 'customerType', label: 'Customer Type' },
                { key: 'amount', label: 'Amount' },
                { key: 'action', label: 'Action' },
            ],

            fieldsExpenses: [
                { key: 'id', label: 'ID' },
                { key: 'expenseDescription', label: 'Description' },
                { key: 'customerType', label: 'Customer Type' },
                { key: 'amount', label: 'Amount' },
                { key: 'action', label: 'Action' },
            ],
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            customerTypes: 'loan/applications/getCustomerTypes',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        incomes() {
            return this.loanApplication?.incomes ?? [];
        },
        expenses() {
            return this.loanApplication?.expenses ?? [];
        },
        totalExpenses() {
            return this.expenses.reduce((acc, curr) => acc + curr.amount, 0);
        },
        totalIncomes() {
            return this.incomes.reduce((acc, curr) => acc + curr.amount, 0);
        },
    },
    methods: {
        // INCOMES
        clearIncomeForm() {
            this.incomeType = null;
            this.incomeAmount = null;
            this.incomeCustomerType = null;
            this.incomeSelected = null;
        },
        async getIncomes() {
            await this.refreshInfo();

            await this.$store.dispatch(
                'loan/applications/get_loanApplicationById',
                this.$route.query.loanApplicationId,
            );
        },
        async getIncomesTypes() {
            this.incomesTypes = await this.$store.dispatch(`loan/financial/get_incomesTypes`);
        },
        async addIncome() {
            this.loading = true;
            const payload = {
                loanApplicationId: this.loanApplication.id,
                customerId: this.incomeCustomerType,
                incomeId: this.incomeType,
                amount: this.incomeAmount,
            };

            await this.$store.dispatch(`loan/financial/add_income`, payload);
            await this.getIncomes();
            this.clearIncomeForm();
            this.loading = false;
        },
        async deleteIncome(id) {
            this.loading = true;
            await this.$store.dispatch(`loan/financial/delete_income`, id);
            await this.getIncomes();
            this.loading = false;
        },
        showEditIncomeModal(data) {
            this.incomeSelected = data;
            this.incomeType = data.incomeId;
            this.incomeAmount = data.amount;
            this.incomeCustomerType = data.customerId;

            this.$bvModal.show('editIncomeModal');
        },
        async refreshInfo() {
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.query.loanApplicationId);
        },
        async editIncome() {
            this.loading = true;
            const payload = {
                id: this.incomeSelected.id,
                incomeId: this.incomeType,
                amount: this.incomeAmount,
            };
            await this.$store.dispatch(`loan/financial/update_income`, payload);
            await this.getIncomes();
            this.$bvModal.hide('editIncomeModal');
            this.clearIncomeForm();
            this.loading = false;
        },

        // EXPENSES
        async getExpenses() {
            await this.refreshInfo();

            await this.$store.dispatch(
                'loan/applications/get_loanApplicationById',
                this.$route.query.loanApplicationId,
            );
        },
        async addExpense() {
            this.loading = true;
            const payload = {
                loanApplicationId: this.loanApplication.id,
                customerId: this.expenseCustomerType,
                expenseId: this.expenseType,
                amount: this.expenseAmount,
            };

            await this.$store.dispatch(`loan/financial/add_expense`, payload);
            await this.getExpenses();
            this.clearExpenseForm();
            this.loading = false;
        },
        async getExpensesTypes() {
            this.expensesTypes = await this.$store.dispatch(`loan/financial/get_expensesTypes`);
        },
        async deleteExpenses(id) {
            this.loading = true;
            await this.$store.dispatch(`loan/financial/delete_expense`, id);
            await this.getExpenses();
            this.loading = false;
        },
        clearExpenseForm() {
            this.expenseType = null;
            this.expenseAmount = null;
            this.expenseCustomerType = null;
            this.expenseSelected = null;
        },
        showEditExpenseModal(data) {
            this.expenseSelected = data;
            this.expenseType = data.expenseId;
            this.expenseAmount = data.amount;
            this.expenseCustomerType = data.customerId;
            this.$bvModal.show('editExpenseModal');
        },
        async editExpenses() {
            this.loading = true;
            const payload = {
                id: this.expenseSelected.id,
                expenseId: this.expenseType,
                amount: this.expenseAmount,
            };
            await this.$store.dispatch(`loan/financial/update_expense`, payload);
            await this.getExpenses();
            this.$bvModal.hide('editExpenseModal');
            this.clearExpenseForm();
            this.loading = false;
        },
    },
    created() {
        this.getIncomesTypes();
        this.getExpensesTypes();
    },
};
</script>
