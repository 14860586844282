<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-card no-body>
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-2" />
                </template>

                <h4>Applicant</h4>
                <div class="d-flex justify-content-between">
                    <b-form-group class="w-25 mb-2">
                        <b-input-group>
                            <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-button variant="danger" size="sm" :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                            >
                        </b-input-group>
                    </b-form-group>
                    <div
                        class="d-flex align-items-center btn-grp"
                        v-if="currentRoute.includes('/applications') && !readOnly"
                    >
                        <b-button variant="primary" v-b-modal.modal-upload-files class="d-flex align-items-center mr-0">
                            <feather type="upload" class="feather-sm"></feather>
                            <span class="ml-1">Upload File</span>
                        </b-button>
                    </div>
                </div>

                <div>
                    <!-- Data Table -->
                    <b-table
                        :style="selectBoxStyle"
                        :per-page="perPage"
                        :current-page="currentPage"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="documents"
                        :filter="filter"
                        :fields="fields"
                        hover
                    >
                        <template #cell(createDate)="data"> {{ $formatDate(data.item.createDate) }} </template>
                        <template #cell(documentDate)="data"> {{ $formatDate(data.item.documentDate) }} </template>
                        <template #cell(documentExpirationDate)="data">
                            {{ $formatDate(data.item.documentExpirationDate) }}
                        </template>
                        <template #cell(isActive)="data">
                            <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                            <b-badge variant="danger" v-else>Archived</b-badge>
                        </template>

                        <template #cell(view)="data">
                            <div class="d-flex">
                                <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                                    <!-- <b-dropdown-item @click="removeDocument(data.item)">Remove</b-dropdown-item> -->
                                    <b-dropdown-item @click="goToView(data.item.sourcePath)">View</b-dropdown-item>
                                    <b-dropdown-item @click="disableDocument(data.item, !data.item.isActive)">
                                        {{ data.item.isActive ? 'Disabled' : 'Enabled' }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </template>
                    </b-table>
                    <!-- End Section -->
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="documents.length"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>

            <b-modal id="modal-upload-files" title="Upload Files" hide-footer centered @close="clearData">
                <b-form @submit.prevent="uploadFiles">
                    <label class="fw-medium mb-1" for="document-types">Customer Type</label>
                    <b-form-select
                        id="document-types"
                        :options="[{ value: null, text: 'Please select an option' }, ...optionsCustomer]"
                        size="sm"
                        class="mb-3"
                        v-model="customerType"
                        required
                    ></b-form-select>
                    <label class="fw-medium mb-1" for="document-types">Document Type</label>
                    <b-form-select
                        id="document-types"
                        :options="[{ id: null, description: 'Please select an option' }, ...documentTypes]"
                        text-field="description"
                        value-field="id"
                        size="sm"
                        class="mb-3"
                        v-model="documentType"
                        required
                    ></b-form-select>

                    <label class="fw-medium mb-1" for="document-date">Document Date</label>
                    <b-form-input
                        id="document-date"
                        type="date"
                        size="sm"
                        class="mb-3"
                        v-model="documentDate"
                        required
                    ></b-form-input>

                    <label class="fw-medium mb-1" for="document-expiration-date">Document Expiration Date</label>
                    <b-form-input
                        id="document-expiration-date"
                        type="date"
                        size="sm"
                        class="mb-3"
                        v-model="documentExpirationDate"
                    ></b-form-input>
                    <div class="d-flex">
                        <b-form-file
                            id="file"
                            v-model="file"
                            placeholder="Choose a file..."
                            drop-placeholder="Drop file here..."
                            accept=".docx,.png,.jpg,.pdf"
                            @input="addFile"
                        ></b-form-file>
                        <!-- <b-button variant="outline-primary" class="d-flex align-items-center ml-2" @click="addFile">
                        <feather type="plus" class="feather-sm"></feather>
                    </b-button> -->
                    </div>
                    <h5 class="mb-2 mt-4" v-if="files.length > 0">Files</h5>
                    <ul class="my-2 p-0">
                        <li
                            v-for="(item, idx) in files"
                            :key="idx"
                            class="d-flex justify-content-between align-items-center p-2 border-bottom"
                        >
                            <span>{{ item.name }}</span>
                            <b-button
                                variant="outline-danger"
                                size="sm"
                                class="d-flex align-items-center ml-2"
                                @click="files.splice(files.indexOf(item), 1)"
                            >
                                <feather type="trash" class="feather-sm"></feather>
                            </b-button>
                        </li>
                    </ul>

                    <div class="d-flex justify-content-end mt-4 btn-grp">
                        <b-button
                            type="button"
                            variant="secondary"
                            @click="$bvModal.hide('modal-upload-files')"
                            class="mr-2"
                        >
                            Cancel
                        </b-button>
                        <b-button
                            variant="primary"
                            class="d-flex align-items-center mr-0"
                            type="submit"
                            :disabled="files.length == 0 || loadingSave"
                        >
                            <b-spinner small v-if="loadingSave"></b-spinner>
                            <template v-else>
                                <feather type="upload" class="feather-sm"></feather>
                                <span class="ml-1">Upload</span>
                            </template>
                        </b-button>
                    </div>
                </b-form>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
export default {
    name: 'ApplicationDocuments',
    components: {
        Header,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        fields: [
            // {
            //     key: 'id',
            //     sortable: true,
            // },
            {
                key: 'documentTypeName',
                label: 'Document Type',
                sortable: true,
            },
            {
                key: 'customerType',
                label: 'Customer Type',
                sortable: true,
            },
            {
                key: 'createdByUserName',
                label: 'Created By',
                sortable: true,
            },
            {
                key: 'createDate',
                label: 'Upload Date',
                sortable: true,
            },
            {
                key: 'documentDate',
                label: 'Document Date',
            },
            {
                key: 'documentExpirationDate',
                label: 'Document Expiration Date',
            },
            // {
            //     key: 'name',
            //     label: 'File Name',
            //     sortable: true,
            // },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        file: null,
        documentType: null,
        customerType: null,
        loading: false,
        files: [],
        currentRoute: window.location.pathname,
        coAppDocs: [],
        guarantorDocs: [],
        loadingSave: false,
        documentDate: null,
        documentExpirationDate: null,
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            documentTypes: 'customer/documents/getDocumentTypes',
            loanApplication: 'loan/applications/getLoanApplication',
            optionsCustomer: 'loan/applications/getCustomerTypes',
        }),
        selectBoxStyle() {
            return {
                minHeight: this.readOnly ? 'auto' : '250px', // min-height condicional
            };
        },
        documents() {
            return this.loanApplication?.documents ?? [];
        },
    },
    methods: {
        clearData() {
            this.file = null;
            this.documentType = null;
            this.files = [];
            this.documentDate = null;
            this.documentExpirationDate = null;
            this.customerType = null;
        },
        async removeDocument(document) {
            await this.$store.dispatch('customer/documents/remove_document', document.id);
            await this.refreshInfo();
        },
        async refreshInfo() {
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.query.loanApplicationId);
        },
        async getData() {
            this.loading = true;
            await this.$store.dispatch(
                'loan/applications/get_loanApplicationById',
                this.$route.query.loanApplicationId,
            );
            this.loading = false;
        },
        async getDocumentTypes() {
            await this.$store.dispatch('customer/documents/get_documentTypes');
        },
        addFile() {
            if (!this.file) return;
            this.files.push(this.file);
            this.file = null;
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]); // Strip metadata if needed
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },

        async uploadFiles() {
            this.loadingSave = true;
            const blob = await this.fileToBase64(this.files[0]);

            let data = {
                customerId: this.customerType,
                loanApplicationId: this.$route.params.customer,
                documentTypeId: this.documentType,
                documentDate: this.documentDate,
                documentExpirationDate: this.documentExpirationDate,
                file: blob,
                fileName: this.files[0].name,
            };

            await this.$store.dispatch('customer/documents/add_documentsInCDN', data);
            await this.getData();
            this.clearData();
            this.$bvModal.hide('modal-upload-files');
            this.loadingSave = false;
        },
        goToView(path) {
            window.open(`${path}`, '_blank');
        },
        async disableDocument(document, status) {
            let data = {
                id: document.id,
                name: document.name,
                sourcePath: document.sourcePath,
                isActive: status,
            };
            await this.$store.dispatch('customer/documents/update_documentStatus', data);
            await this.getData();
        },
    },
    mounted() {
        if (!this.readOnly) {
            this.fields.push({
                key: 'view',
                label: 'View',
            });
        }
    },
    created() {
        if (!this.new) {
            this.getData();
        }
        this.getDocumentTypes();
    },
};
</script>
