<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-card no-body class="">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-4" />
                </template>

                <div class="d-flex justify-content-end mb-3">
                    <b-button variant="primary" class="mr-2" v-b-modal.modal-add-obligation v-if="hasPermission">
                        Add obligation
                    </b-button>
                </div>

                <div class="border" v-for="(item, idx) in items" :key="item.id" v-b-toggle="`accordion-${idx}`">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr" class="p-2">
                        <div class="p-2">
                            <strong>{{ item.type }}</strong>
                        </div>
                        <div class="p-2"><strong>Screening send date: </strong>{{ $formatDate(item.sendDate) }}</div>
                        <div class="p-2"><strong>Screening status: </strong>{{ item.status }}</div>
                        <div class="p-2">
                            <strong>Completion date: </strong>{{ $formatDate(item.lastCompletionDate) }}
                        </div>
                        <div class="p-2"><strong>Requires new screening: </strong>{{ item.requiresNewScreening }}</div>
                    </div>

                    <b-collapse :id="`accordion-${idx}`" class="w-100" :visible="item.type == 'Applicant'">
                        <div class="px-3 pb-2">
                            <b-table
                                style="max-height: 400px; width: 100%"
                                :items="item.screenings"
                                :fields="screeningFields"
                                small
                                show-empty
                                responsive
                                bordered
                                head-variant="light"
                                hover
                            >
                                <template v-slot:cell(isActive)="data">
                                    <b-badge :variant="data.item.isActive ? 'success' : 'danger'">{{
                                        data.item.isActive ? 'Active' : 'Inactive'
                                    }}</b-badge>
                                </template>
                                <template v-slot:cell(createDate)="data">
                                    {{ $formatDate(data.item.createDate) }}
                                </template>
                                <template v-slot:cell(modifiedDate)="data">
                                    {{ $formatDate(data.item.modifiedDate) }}
                                </template>

                                <template v-slot:cell(action)>
                                    <!-- <b-button @click="showModal(data.item)" variant="primary" size="sm"
                                        >Edit customer</b-button
                                    > -->
                                </template>
                            </b-table>
                        </div>
                    </b-collapse>
                </div>
            </b-card>

            <b-modal id="modal-add-obligation" hide-footer centered title="Financial Obligations" size="md">
                <FinancialObligations />
            </b-modal>

            <!-- Modal disclaimer send Verifications -->
            <b-modal id="modal" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to sent verifications? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal')">No</b-button>
                    <b-button variant="primary" @click="sendAllVerifications">
                        <b-spinner small v-if="loadingSendVerifications"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import FinancialObligations from '@/components/applications/FinancialObligations.vue';
export default {
    name: 'ApplicationScreening',
    components: {
        Header,
        FinancialObligations,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        screeningFields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'createDate',
                label: 'Request Date',
            },
            {
                key: 'bankDescription',
                label: 'Bank',
            },
            {
                key: 'obligationTypeDescription',
                label: 'Loan Type',
            },

            {
                key: 'modifiedDate',
                label: 'Date Screened',
            },
            {
                key: 'amount',
                label: 'Balance',
            },
            {
                key: 'montlyPayment',
                label: 'Monthly Payment',
            },
            {
                key: 'isActive',
                label: 'Status',
            },
            {
                key: 'comment',
                label: 'Comments',
            },
            {
                key: 'action',
                label: 'Action',
            },
        ],

        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        screeningSelected: null,
        loading: false,
        loadingSendVerifications: false,
        loadingUpdateStatus: false,
    }),
    methods: {
        async getScreeningVerifications() {
            this.loading = true;
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.query.loanApplicationId);
            this.loading = false;
        },
        async sendAllVerifications() {
            this.loadingSendVerifications = true;
            await this.$store.dispatch('customer/screening/send_Emails', this.$route.query.loanApplicationId);
            await this.getScreeningVerifications();
            this.loadingSendVerifications = false;
        },
        async updateStatus(status) {
            this.loadingUpdateStatus = true;
            await this.$store.dispatch('customer/screening/update_screeningStatus', {
                id: this.screeningSelected.id,
                isActive: status,
            });
            await this.getScreeningVerifications();
            this.screeningSelected = null;
            this.$bvModal.hide('modal-disable-screening');
            this.$bvModal.hide('modal-enable-screening');
            this.loadingUpdateStatus = false;
        },
        getLink(item) {
            //copy to clipboard
            const url = `${window.location.origin}/screening-verification/${item.guid}?id=${item.id}`;
            if (navigator.clipboard) {
                navigator.clipboard.writeText(url);
                this.$bvToast.toast(`Link copied to clipboard`, {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
            }
        },
        disabledVerification(item) {
            this.screeningSelected = item;
            this.$bvModal.show('modal-disable-screening');
        },
        enableVerification(item) {
            this.screeningSelected = item;
            this.$bvModal.show('modal-enable-screening');
        },

        getDaysSinceLastDate(date) {
            if (date) {
                return 0;
            }
            const lastScreeningDate = new Date(date);
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate - lastScreeningDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        },
        buildScreeningData(screenings) {
            const screeningBatchs = screenings?.screeningsBatchs;
            const daysLastScreeningDate = this.getDaysSinceLastDate(screeningBatchs?.lastCompletionDate);
            const requiresNewScreening = daysLastScreeningDate > 14 ? 'Yes' : 'No';
            return {
                sendDate: screeningBatchs?.date,
                status: screeningBatchs?.state || 'N/D',
                lastCompletionDate: screeningBatchs?.lastCompletionDate,
                daysLastScreeningDate,
                requiresNewScreening,
                screenings: screenings?.screeningsRecords,
            };
        },
    },
    computed: {
        ...mapGetters({
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Screening');
        },
        screening() {
            return this.loanApplication?.screening ?? [];
        },

        items() {
            const data = [];
            if (this.loanApplication.applicantInfo?.screenings) {
                data.push({
                    type: 'Applicant',
                    ...this.buildScreeningData(this.loanApplication.applicantInfo?.screenings),
                });
            }
            if (this.loanApplication.coApplicantInfo?.screenings) {
                data.push({
                    type: 'CoApplicant',
                    ...this.buildScreeningData(this.loanApplication.coApplicantInfo?.screenings),
                });
            }
            //guarantorInfo
            if (this.loanApplication.guarantorInfo?.screenings) {
                data.push({
                    type: 'Guarantor',
                    ...this.buildScreeningData(this.loanApplication.guarantorInfo?.screenings),
                });
            }

            return data;
        },
    },
    mounted() {},
    created() {},
};
</script>
